
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <div class="reports-header">
        <div class="report-top-labels">
          <h2>{{ title }}</h2>

          <span class="number-of-views-title">
            [ <span>{{ numberOfReportViewsTitle(report) }}</span> ]  <!-- Debug <input type="checkbox" v-model="debugEnabled"> -->
          </span>
          <button class="button primary rounded add-view-button" @click="onAddView">
                <inline-svg class="add-icon" :src="require('@/assets/svg/icons/reports/icon-add-small.svg')" width="10" height="10"></inline-svg>
          </button>
        </div>
        <div class="report-view-switch-container">
          <ReportViewSwitch v-model="presentationStyle" @click="onPresentationStyleChanged" />
        </div>
        <div class="report-top-actions">
          <div class="parameter-tags"></div>
          <div class="add-report-view">
          </div>
          <button class="button primary save-report" :disabled="isSaveButtonDisabled" @click="onSaveReport">{{ saveButtonTitle }}</button>
          <div :class="'download' + (downloadEnabled ? '' : ' disabled')" @click="onDownload">
            <inline-svg
                class="icon-download"
                :src="require('@/assets/svg/icons/right_sidebar/icon_download_20x15px.svg')" width="15" height="12">
            </inline-svg>
          </div>
          <div class="icon-button"
            @click.stop="$store.dispatch('ui/toggleContextMenu', { event: $event, data: report, menuItems: contextMenuItems })">
            <inline-svg
              :src="require('@/assets/svg/icons/reports/icon-dots.svg')" width="18" height="18">
            </inline-svg>
          </div>
        </div>
      </div>
      <JsonView :data="report" v-if="$store.getters['ui/debug']"/>
      <TabView
        v-if="presentationStyle == 'tabs'"
        v-show="views && views.length"
        :titles="reportViewTitles"
        :selectedTabIndex="currentViewIndex"
        @tab:index="onTabIndexChange"
        >
        <ReportView
          v-if="currentView"
          class="report-view"
          :reportViewData="currentView"
          :viewIndex="currentViewIndex"
          @view:delete="onDeleteView"
        />
      </TabView>
      <div v-if="report && (presentationStyle == 'list' || presentationStyle == 'grid')"
          :class="(presentationStyle == 'grid' ? 'reports-grid-view' : 'reports-list-view') + (report.views.length == 1 ? ' single-item' : '')"
      >
        <ReportView
          v-for="(view, index) in report.views" :key="index"
          :class="'report-view' + (index == currentViewIndex ? ' active' : '')"
          :reportViewData="view"
          :viewIndex="index"
          :showActive="true"
          @click="onTabIndexChange(index)"
          @view:delete="onDeleteView"
        />
      </div>
    </div>
    <ConfirmDialog v-if="viewToBeDeletedIndex > -1" title="Delete view?">
      <template v-slot:message><span v-html="deleteViewConfirmMessage"></span></template>
      <template v-slot:actions>
        <div class="button-group">
          <button @click.stop="doCancelDeleteView" class="button ghost mediumsize rounded">Cancel</button>
          <button @click.stop="doDeleteView" class="button primary mediumsize rounded">Delete</button>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmDialog v-if="reportToBeSaved" title="Save report" class="save-confirm-dialog">
      <template v-slot:message>Please enter the name of the report.</template>
      <template v-slot:content>
        <TextInput class="input-report-name" type="text" v-model="reportToBeSaved.title" @keyup.enter="doSave(reportToBeSaved)"/>
      </template>
      <template v-slot:actions>
        <div class="button-group">
          <button :disabled="isSaveButtonDisabled" @click.stop="doSaveAs(reportToBeSaved)" class="button ghost mediumsize rounded save-as">Save as new Report</button>
          <button :disabled="isSaveButtonDisabled" @click.stop="doSave(reportToBeSaved)" class="button primary mediumsize rounded save">Save</button>
        </div>
        <button @click.stop="onCancelSave" class="button plain third-button">{{ cancelButtonText }}</button>

      </template>
    </ConfirmDialog>
    <ConfirmDialog v-if="reportToBeSavedAs" title="Save report as">
      <template v-slot:message>Please enter a new name for the report.</template>
      <template v-slot:content>
        <TextInput class="input-report-name" type="text" v-model="reportToBeSavedAs.title" @keyup.enter="doSave(reportToBeSavedAs)"/>
      </template>
      <template v-slot:actions>
        <div class="button-group">
          <button @click.stop="onCancelSave" class="button ghost mediumsize rounded">{{ cancelButtonText }}</button>
          <button :disabled="isSaveAsButtonDisabled" @click.stop="doSave(reportToBeSavedAs)" class="button primary mediumsize rounded">Save</button>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmDialog v-if="reportToBeDeleted" title="Delete report?">
      <template v-slot:message><span v-html="deleteConfirmMessage"></span></template>
      <template v-slot:actions>
        <div class="button-group">
          <button @click.stop="onCancelDelete" class="button ghost mediumsize rounded">Cancel</button>
          <button @click.stop="doDeleteReport" class="button primary mediumsize rounded">Delete</button>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmDialog v-if="reportToBeRenamed" title="Rename report">
        <template v-slot:message>Please enter a new name for the report.</template>
        <template v-slot:content>
          <TextInput class="input-report-name" type="text" v-model="reportToBeRenamed.title" @keyup.enter="doRename"/>
        </template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="onCancelRename" class="button ghost mediumsize rounded">Cancel</button>
            <button :disabled="isRenameButtonDisabled" @click.stop="doRename" class="button primary mediumsize rounded">Rename</button>
          </div>
        </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import ReportView from "@/components/reports/ReportView.vue";
import ReportMixin from "@/components/mixins/ReportMixin.vue";
import TabView from "@/components/ui/TabView.vue";
import UiMixin from "@/components/mixins/UiMixin.vue"
import JsonView from "@/components/ui/JsonView.vue"
import useUI from '@/js/useUI.js'
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import TextInput from "@/components/ui/TextInput.vue"
import ReportViewSwitch from "@/components/ui/ReportViewSwitch.vue"

export default {
  name: 'ReportPage',
  mixins: [ReportMixin, UiMixin],
  components: {
    ReportView,
    TabView,
    JsonView,
    ConfirmDialog,
    TextInput,
    ReportViewSwitch
  },
  props: {
  },
  data: function() {
    return {
      viewToBeDeletedIndex: -1,
      routeAfterSave: null
    }
  },
  computed: {
    views() {
      return this.report?.views || []
    },
    title() {
      return this.report?.title || ""
    },
    report() {
      return this.$store.getters["reports/currentReport"]
    },
    reportViewTitles() {
      return this.report?.views?.map( v => v.title ) || []
    },
    saveButtonTitle() {
      return this.report?.predefined || this.report?.locked ? "Save as new report" : "Save report"
    },
    isSaveAsButtonDisabled() {
      return !this.reportToBeSavedAs.title?.length
    },
    isRenameButtonDisabled() {
      return !this.reportToBeRenamed.title?.length
    },
    currentView() {
      return this.$store.getters["reports/currentView"]
    },
    currentViewIndex: {
      get() {
        return this.$store.getters["reports/currentViewIndex"]
      },
      set(idx) {
        this.$store.commit("reports/currentViewIndex", idx)
      }
    },
    debugEnabled: {
      get() {
        return this.$store.getters["ui/debug"]
      },
      set(value) {
        this.$store.commit("ui/debug", value)
      }
    },
    deleteViewConfirmMessage() {
      return `Do you really want to delete this view?`
    },
    reportId() {
      let reportId = this.$route.params.reportId
      return reportId
    },
    downloadEnabled() {
      return true //!this.report?.transient
    },
    contextMenuItems() {
      let items = [
        {
          title: "Save as new report",
          handler: () => {
              useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
              this.reportToBeSavedAs = JSON.parse(JSON.stringify(this.report))
              delete this.reportToBeSavedAs.id
              delete this.reportToBeSavedAs.predefined
            },
        }
      ]
      if (!this.report.predefined && !this.report.locked) {
        items.push(
        {
          title: "Rename",
          handler: () => {
              useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
              this.reportToBeRenamed = this.report
            },
        })
        items.push(
        {
          title: "Delete",
          handler: () => {
            useUI(this.$store).blockUI({ complete: true })
            this.reportToBeDeleted = this.report
          },
        })
      }
      return items
    },
    presentationStyle: {
      get() {
        //return this.report?.style?.presentationStyle || "tabs"
        return this.$store.getters["user/reportPresentationStyle"]
      },
      set(value) {
        this.$store.commit("user/reportPresentationStyle", value)
        // if (!this.report.style) {
        //   this.report.style = {}
        // }
        // this.report.style.presentationStyle = value
      }
    },
  },
  methods: {
    onPresentationStyleChanged() {
      // this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
      //   this.$store.commit("ui/error", err)
      // })
    },
    navBack(path) {
      path = path.replace(":reportId", this.reportId)
      path = path.replace(":viewIndex", this.currentViewIndex)
      path = path.replace(":datasetIndex", this.$store.getters["reports/currentDatasetIndex"])
      this.$router.push(path)
    },
    onSaveReport() { // Save Button click, not context menu
      useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
      if (!this.report.id || this.report.predefined || this.report.locked) {
        this.reportToBeSavedAs = this.report
      } else {
        this.doSave(this.report)
      }
    },
    onDownload() {
      if (this.downloadEnabled) {
        if (this.report.transient) {
          this.download("reports/downloadTransientReport", this.report)
        } else {
          this.download("reports/downloadReport", this.report.id)
        }
      }
    },
    onTabIndexChange(index) {
      this.currentViewIndex = index
      this.$router.push(`/reports/${this.reportId}/views/${this.currentViewIndex}`)
    },
    onAddView() {
      this.$store.dispatch("reports/addNewView", this.report).then( (updatedReport) => {
        this.currentViewIndex = updatedReport.views.length - 1
        this.currentDatasetIndex = 0
        this.$store.commit("reports/currentReport", updatedReport)
        this.$router.push(`/reports/current/views/${this.currentViewIndex}/datasets/0/filters`)
      })
    },
    onDeleteView(viewIndex) {
      this.viewToBeDeletedIndex = viewIndex
    },
    doDeleteView() {
      this.$store.dispatch("reports/deleteView", {
        report: this.report,
        viewIndex: this.viewToBeDeletedIndex
      }).then( (updatedReport) => {
        if (updatedReport.views.length == 0) {
          this.onAddView()
        }
        this.currentViewIndex = Math.max(0, this.viewToBeDeletedIndex - 1)
        this.viewToBeDeletedIndex = -1
        useUI(this.$store).unblockUI()
      })
    },
    doCancelDeleteView() {
      useUI(this.$store).unblockUI()
      this.viewToBeDeletedIndex = -1;
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      let reportId = to.params.reportId
      if (reportId == "current") {
        if (!vm.$store.getters["reports/currentReport"]) {
          vm.$router.push("/reports")
        }
        vm.$store.commit("reports/currentViewIndex", parseInt(to.params.viewIndex || "0"))
        vm.$store.commit("reports/currentDatasetIndex", parseInt(to.params.datasetIndex || "0"))

      } else if (!reportId) {
        vm.$store.commit("ui/error", "Report Id missing")
      } else {
        vm.$store.commit("ui/isFetchingChartData", true)
        useUI(vm.$store).blockUI({ loading: true, complete: false, chart: true })

        vm.$store.dispatch("reports/fetchReport", reportId).then( (report) => {
          vm.$store.commit("reports/currentReport", report)
          vm.$store.commit("reports/currentViewIndex", parseInt(to.params.viewIndex || "0"))
          vm.$store.commit("reports/currentDatasetIndex", parseInt(to.params.datasetIndex || "0"))

          useUI(vm.$store).unblockUI()
        })
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    this.routeAfterSave = null
    let wouldLeaveReports = !to.path.startsWith("/reports/")
    if (this.report?.transient && wouldLeaveReports && !this.leaveWithoutSaving) {
      useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
      if (!this.report.predefined) {
        this.reportToBeSaved = JSON.parse(JSON.stringify(this.report))
      } else {
        this.reportToBeSavedAs = JSON.parse(JSON.stringify(this.report))
      }
      this.routeAfterSave = to
      this.cancelButtonText = "Don't save"
    } else {
      this.leaveWithoutSaving = false
      next()
    }
  },
}
</script>

<style src="@/assets/css/reports.css" />
<style scoped>
h2 {
  text-align: left;
}
.report-top-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}
.parameter-tags {
  flex-grow: 1;
  text-align: left;
}
.download {
  width: 34px;
  height: 34px;
  border: 1px solid #E6EBF7;
  background: #fff;
  border-radius: 7px;
  color: var(--c-button-blue);
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.add-report-view button {
  height: 34px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  font-weight: 400;
}
/* .add-report-view button svg {
  margin-right: 10px;
} */
.reports-header h2 {
  flex-grow: 0;
  margin-right: 20px;
}

.reports-header {
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  overflow: hidden;
  padding-bottom: 25px;
}

.report-view-switch-container {
  margin: 0 2em 0 2em;
}

.report-top-labels {
  display: inline-flex;
  align-items: center;
  padding-bottom: 10px;
}

.report-top-labels h2 {
  /* Spacing top/bottom defined by the large + button, no vspace waste here */
  margin-top: 0px;
  margin-bottom: 0px;
}

span.number-of-views-title {
  color: var(--c-greyish);
  white-space: nowrap;
}

span.number-of-views-title span {
  color: var(--c-button-blue);
}
.download.disabled {
  opacity: 0.25;
  cursor: default;
}
.button.save-report {
  height: 34px;
}
.button.add-view-button {
  height: 34px;
  width: 34px;
  padding: 0;
  margin-left: 20px;
}
.button.add-view-button svg {
  transform: translate(0px, 1px);
}
.icon-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 24px;
  height: 24px;
}
.button.third-button{
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
}
.button.save, .button.save-as {
  min-width: 130px;
  max-width: 130px;
}

.reports-grid-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
}
:deep(.reports-grid-view .report-view .chart-wrapper) {
  min-height: 300px;
  max-height: 300px;
}
:deep(.reports-grid-view .report-view .mx-datepicker) {
  width: 120px;
}
:deep(.save-confirm-dialog .actions) {
  width: 360px;
}

:deep(.reports-list-view .report-view) {
  margin-bottom: 40px;
}
:deep(.reports-grid-view .report-view) {
  margin-bottom: 40px;
  max-width: 100%;
  flex-grow: 1;
}

:deep(.reports-grid-view .report-view .report-chart .header) {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
}

:deep(.reports-grid-view .report-view .report-chart .header .date-picker-wrapper) {
  margin-top: 20px;
}

:deep(.reports-grid-view .dropdown), :deep(.reports-grid-view .dropdown .selectbox) {
  max-width: 120px;
  min-width: 120px;
}
:deep(.reports-grid-view .dropdown) {
  margin-bottom: 20px;
}
:deep(.reports-grid-view .report-view .from-date-picker) {
  margin-bottom: 20px;
}
:deep(.reports-grid-view .report-view .to-date-picker) {
  margin-bottom: 20px;
}

:deep(.reports-grid-view .report-view .from-date-picker.expanded) {
    width: 250px;
}

:deep(.reports-grid-view .report-view .from-date-picker.expanded .mx-datepicker) {
    width: 250px;
}

:deep(.reports-grid-view .report-view .date-format-hint) {
  display: none;
}

.scrollable-container.main {
  min-width: 800px;
}

@media screen and (max-width: 1550px) {
  :deep(.reports-grid-view .report-view) {
    max-width: 48%;
  }
  :deep(.reports-grid-view.single-item .report-view) {
    max-width: 100%;
  }

  :deep(.reports-grid-view .report-view .date-picker-wrapper) {
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }


}
</style>
