
<template>
  <div :class="'report-view' + (showActive ? ' show-active' : '')">
    <JsonView :data="chartData" v-if="$store.getters['ui/debug']"/>
    <ChartDiagram
      :showChartJSLegend="false"
      :chartPlugins="chartPlugins"
      class="report-chart"
      :title="title"
      :yAxes="yAxes"
      :errorMessage="errorMessage"
      :chartData="chartData"
      :user-chart-options="userChartOptions"
      :showLabelTexts="false"
      :showMediaSpendingsLabels="false"
      :subtitle="chartSubtitle"
      :legendOnClickHandler="legendOnClickHandler">
      <template #header-actions>
        <div class="date-picker-wrapper">
          <DatePicker
            :class="'from-date-picker' + (isFromDatePickerExpanded ? ' expanded' : '')"
            v-model="dateFrom"
            :type="datePickerGranularity"
            :placeholder="toDatePickerFormattedValue  || 'Start date'"
            :disabledDate="disabledDateFrom"
            :shortcuts="shortcutsDateTo"
            :formattedValue="toDatePickerFormattedValue"
            :dataweekStart="dataweekStart"
          />
          <DatePicker
            v-show="!isFromDatePickerExpanded"
            class="to-date-picker"
            v-model="dateTo"
            :type="datePickerGranularity"
            :placeholder="toDatePickerFormattedValue || 'End date'"
            :disabledDate="disabledDateTo"
            @open="onOpenDateToPicker"
            :formattedValue="toDatePickerFormattedValue"
            :dataweekStart="dataweekStart"
          />
          <DropDown
            bgColor="#fff"
            placeholder="Granularity"
            class="white-dropdown granularity"
            :allowNoSelection="false"
            :options="granularityOptions"
            :modelValue="reportViewData.granularity"
            @update:modelValue="onGranularityChanged"
          ></DropDown>
        </div>
        <div class="icon-button context-menu-button"
          @click.stop="$store.dispatch('ui/toggleContextMenu', { event: $event, data: reportViewData, menuItems: contextMenuItems })">
          <inline-svg
            :src="require('@/assets/svg/icons/reports/icon-dots.svg')" width="18" height="18">
          </inline-svg>
        </div>
      </template>
    </ChartDiagram>
    <div :id="randomId" class="legend-container">
      <!--
        TODO: use a legend-list component (to be written) here instead of manually creating
        the labels. Problem: needs to interact with the chart (double click etc.)
        <ul class="legend-list"></ul>
      -->
    </div>
    <ConfirmDialog v-if="viewToBeRenamed" title="Rename view">
        <template v-slot:message>Please enter a new name.</template>
        <template v-slot:content>
          <TextInput class="input-report-name" type="text" v-model="viewToBeRenamed.title" @keyup.enter="doRenameView" />
        </template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="onCancelRenameView" class="button ghost mediumsize rounded">Cancel</button>
            <button :disabled="isSaveViewButtonDisabled" @click.stop="doRenameView" class="button primary mediumsize rounded">Rename</button>
          </div>
        </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import ChartDiagram from "@/components/chart/ChartDiagram.vue";
import DatePicker from "@/components/ui/DatePicker.vue";
import ReportMixin from "../mixins/ReportMixin.vue";
import ReportPlotMixin from "../mixins/ReportPlotMixin.vue";
import DropDown from "../forms/DropDown2.vue";
import JsonView from "@/components/ui/JsonView.vue"
import useUI from '@/js/useUI.js'
import { provide } from 'vue';
import Util from "@/util.js"
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import TextInput from "@/components/ui/TextInput.vue"
import moment from "moment"
import { palettes } from "@/store/reports.module.js"
import tinycolor from "tinycolor2"

function normalizeDate(inputDate, type, granularity, dataweekStart) {
  const date = moment(inputDate);
  switch (granularity) {
    case 'dataweek': {
      const shiftDays = Util.dataweekConfig(dataweekStart).shiftToIsoWeek
      return type === 'start'
        ? date.add(shiftDays, 'd').startOf('isoWeek').add(-shiftDays, 'd').toDate()
        : date.add(shiftDays, 'd').endOf('isoWeek').add(-shiftDays, 'd').toDate();
    }
    case 'week':
      return type === 'start' ? date.startOf('isoWeek').toDate() : date.endOf('isoWeek').toDate();
    case 'month':
    case 'quarter':
    case 'year':
      return type === 'start' ? date.startOf(granularity).toDate() : date.endOf(granularity).toDate();
    default:
      throw new Error(`Unsupported granularity: ${granularity}`);
  }
}

export default {
  name: "ReportView",
  mixins: [ReportMixin, ReportPlotMixin],
  setup(props) {
    const tooltipTitle = (context) => {
      let ctx = context[0]
      let plotIndex = ctx.datasetIndex  // chartjs calls dataset what we call plot
      if (plotIndex === undefined) return "Information not available"

      // Each dataset contains a list of plots (one plot without grouping, multiple plots when
      // grouping is selected). In ChartJS, we just have a flat list of all plots which we
      // need to index into. Therefore flatten the "dataset" array of the hoverLabels.
      let allHoverLabels = props.reportViewData?.datasets?.reduce( (accu, dataset) => {
          accu.push(...dataset.chartData.hoverLabel)
          return accu
        }, []
      )
      // check whether the data is selected to be shown in relative
      const rel = props.reportViewData?.datasets?.reduce( (accu, ds) => {
        const ds_rel = !!props.reportViewData?.style?.sourceType?.[ds?.sourceType]?.relative
        accu.push(...Array(ds.chartData.y.length).fill(ds_rel))
        return accu
      }, [])

      const labelAbs = allHoverLabels[plotIndex][ctx.dataIndex]
      if (!rel[plotIndex]) return labelAbs

      // add percentage to tooltip
      const pct = (100 * ctx.raw).toFixed(2) + " %"
      // TODO: need a better way to insert percentage, this is a hack to make it work but it
      // highly depends on the data generated by the backend. Current hackish logic:
      // If tooltip has three rows, insert at the end of the second row, otherwise add a row
      // at the top.
      let labelRows = labelAbs.split("\n")
      if (labelRows.length == 3) {
        labelRows[1] += "  /  "  + pct
        return labelRows.join("\n")
      }
      return pct + "\n" + labelAbs
    }
    const tooltipLabel = () => {
      return ""
    }
    provide('tooltipTitle', tooltipTitle);
    provide('tooltipLabel', tooltipLabel);
  },
  components: {
    ChartDiagram,
    DatePicker,
    DropDown,
    JsonView,
    ConfirmDialog,
    TextInput,
  },
  props: {
    reportViewData: {
      type: Object
    },
    viewIndex: {
      type: Number,
      default: 0
    },
    showActive: {
      type: Boolean,
      default: false
    },
    maxPlotsToShow: {
      type: Number,
      default: 100
    },
    randomId: {
      // Each of the ReportViews has its own unique ID. We use the ID to find the element
      // where the legend is to be placed.
      // It would be great if we would manage to do it in vue instead of manually adding
      // the elements to the DOM tree in the chartjs plugin.
      type: String,
      default: () => "legend-id-" + crypto.randomUUID()
    },
  },
  data: function() {
    return {
      viewToBeRenamed: null,
      onSelectPredefinedDateToRange: (value) => {
        if (value == "custom") {
          this.report.views[this.viewIndex].period.daterangePredefined = null
          this.updateShortcutStyles()
        } else {
          this.report.views[this.viewIndex].period.dateTo = null
          this.report.views[this.viewIndex].period.dateFrom = null
          this.report.views[this.viewIndex].period.daterangePredefined = value
          this.updateShortcutStyles()
        }
        this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
             this.$store.commit("ui/error", err)
        })
      },
    }
  },
  methods: {

    legendOnClickHandler(e, legendItem) {

      let view = this.$store.getters['reports/currentView']

      let count = 0
      let datasetIndex = 0
      let pos = view.datasets.reduce( (position, ds) => {
        for (var y=0; y < ds.chartData.y.length; y++) {
          if (count == legendItem.datasetIndex) {
            position = { datasetIndex, y }
          }
          count++
        }
        datasetIndex++
        return position
      }, {})

      if (pos.datasetIndex != undefined) {
        let datasetStyle = view.datasets[pos.datasetIndex].style
        if (datasetStyle.invisible) {
          return
        }
        if (!datasetStyle.y) {
          datasetStyle.y = {}
        }
        if (datasetStyle.y[pos.y] != undefined) {
          delete datasetStyle.y[pos.y].invisible
          if (Object.keys(datasetStyle.y[pos.y]).length == 0) {
            delete datasetStyle.y[pos.y]
          }
          if (Object.keys(datasetStyle.y).length == 0) {
            delete datasetStyle.y
          }
        } else {
          datasetStyle.y[pos.y] = {
            invisible: true
          }
        }
      }

    },
    onOpenDateToPicker() {
      this.$nextTick( () => {
        this.updateShortcutStyles()
      })
    },
    disabledDateFrom(date) {
      if (this.isDateRangePredefined) return true
      if (this.currentView.period.dateTo) {
        return (date >= new Date(Date.parse(this.currentView.period.dateTo + " 00:00:00")))
      }
      return false
    },
    disabledDateTo(date) {
      if (this.isDateRangePredefined) return true
      if (this.currentView.period.dateFrom) {
        return (date <= new Date(Date.parse(this.currentView.period.dateFrom + " 00:00:00")))
      }
      return false
    },
    onGranularityChanged(value) {
      this.report.views[this.viewIndex].granularity = value
      this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
        this.$store.commit("ui/error", err)
      })
    },
    onRenameView() {
      this.viewToBeRenamed = JSON.parse(JSON.stringify(this.reportViewData))
    },
    onCancelRenameView() {
      useUI(this.$store).unblockUI()
      this.viewToBeRenamed = null;
    },
    doRenameView() {
      if (this.viewToBeRenamed.title.length == 0) {
        return
      }
      this.report.views[this.viewIndex] = this.viewToBeRenamed
      this.report.save = true
      let mutation = this.report.transient ? "reports/updateTransient" : "reports/updateReport"
      this.$store.dispatch(mutation, this.report).then( () => {
        this.viewToBeRenamed = null
      }).catch( err => {
        this.$store.commit("ui/error", err)
      }).finally( () => {
        useUI(this.$store).unblockUI()
      })
    },
    setDate(newDate, key) {
      let date = newDate ? moment(newDate).format("YYYY-MM-DD") : null
      let view = this.report.views[this.viewIndex]
      view.period[key] = date ? moment(date).format("YYYY-MM-DD") : null
      this.report.views[this.viewIndex].period.daterangePredefined = null
      this.$store.dispatch("reports/updateTransient", this.report).catch( err => {
        this.$store.commit("ui/error", err)
      })
      this.updateShortcutStyles()
    },
    updateShortcutStyles() {
      document.querySelectorAll('.mx-datepicker-sidebar > button').forEach( el => {
        el.style.color = "#73879c"
      })
      let placeholderEl = document.querySelector('.mx-datepicker-sidebar > button:nth-child(2)');
      if (placeholderEl) {
        placeholderEl.style.color = "var(--c-medium-grey)"
      }

      let daterangePredefined = this.currentView.period.daterangePredefined
      let idx = 0
      if (this.isDateRangePredefined) {
        idx = this.predefinedDateRanges.map( p => p.key).indexOf(daterangePredefined)
        if (idx > -1) {
          idx += 1
        } else {
          idx = 0
        }
      }
      let el = document.querySelector(`.mx-datepicker-sidebar > button[data-index = "${idx == 0 ? 0 : idx + 1}"]`)
        if (el && el.style) {
          el.style.color = "var(--c-button-blue)"
        }
    },
    datasetDimensionName(dataset) {
      const sourceType = dataset?.sourceType
      return this.labels.views.datasets.types[sourceType]?.dimension
    },
    getYAxesConfiguration(view) {
      const datasets = view?.datasets ?? []
      let sourceTypes = datasets.map(ds => ds.sourceType ?? "revenue")

      // make source types unique
      sourceTypes = sourceTypes.filter((element, index) => {
        return sourceTypes.indexOf(element) === index;
      })

      let axesBySourceType = {}
      for (const [i, sourceType] of sourceTypes.entries()) {
        const style = this.getSourceTypeStyle(view, sourceType)
        const relative = style?.relative
        const sourceTypeConfig = this.getSourceTypeConfig(sourceType)

        axesBySourceType[sourceType] = {
          axisID: `y${i}`,
          title: sourceTypeConfig?.yTitle ?? "",
          tickLabelPrefix: relative ? "" : sourceTypeConfig?.yTickLabelPrefix,
          tickLabelPostfix: relative ? " %" : sourceTypeConfig?.yTickLabelPostfix,
          position: (i == 0 ? "left" : "right"),
          yAutoDivide: true,
          stack: `y${i}`,
          stacked: style?.stacked,
          type: style?.log ? "logarithmic" : "linear",
          relative: relative,
        }
      }

      return axesBySourceType
    },
    sumArrays(arr1, arr2) {
      return arr1.map( (a1val, idx) => { return a1val + arr2[idx] })
    },
    divideArrays(arr1, arr2) {
      return arr1.map( (a1val, idx) => { return arr2[idx] == 0 ? 0 : a1val / arr2[idx] })
    },
  },
  computed: {
    userChartOptions() {
      const tooltipAlpha = 0.9
      return {
        plugins: {
          tooltip: {
            padding: {top: 6, left: 6, bottom: 0, right: 6},
            titleFont: {
              family: "Arial, Helvetica, sans-serif",
              style: "normal",
              size: 13,
              weight: "normal",
              lineHeight: 1.3,
            },
            cornerRadius: 5,
            borderWidth: 1,
            borderColor: "black",
            displayColors: false,
            backgroundColor: (item) => {
              let color = tinycolor(item.tooltip.labelColors[0].backgroundColor)
              color.setAlpha(tooltipAlpha)
              return color.toString()
            },
            titleColor: (item) => {
              let bgColor = tinycolor(item.tooltip.labelColors[0].backgroundColor)
              // assume white background, i.e. the tooltip color is lighter than bgColor
              bgColor.lighten(100 * (1 - tooltipAlpha))  // alpha from 0-1, lighten takes 0-100
              return bgColor.isDark() ? "white" : "black"
            },
            // yAlign: "bottom",  // = position of the caret, default = choose automatically
          }
        }
      }
    },
    shortcutsDateFrom() {
      return this.predefinedDateRanges?.map( p => {
        return { text: p.label, onClick: this.onSelectPredefinedDateFromRange(p.key) }
      }) || null
    },
    shortcutsDateTo() {
      return [
        {
          text: "Custom date",
          onClick: () => this.onSelectPredefinedDateToRange("custom")
        },
        {
          text: "Predefined ranges",
          onClick: () => {}
        }
      ].concat(
          this.predefinedDateRanges?.map( p => {
            return { text: p.label, onClick: () => this.onSelectPredefinedDateToRange(p.key) }
          })
      ) || []
    },
    labels() {
      return this.$store.getters["config/labels"]
    },
    dataweekStart() {
      return this.$store.getters["config/dataweekStart"]
    },
    predefinedDateRanges() {
      return this.labels.views.period.daterangePredefined
    },
    currentView() {
      return this.report?.views?.[this.viewIndex] || null
    },
    report() {
      return this.$store.getters["reports/currentReport"]
    },
    datePickerGranularity() {
      let granularity = this.report.views[this.viewIndex].granularity
      switch (granularity) {
        case "quarter":
          return "month";
        default:
          return granularity
      }
    },
    isFromDatePickerExpanded() {
      return this.currentView?.period?.daterangePredefined != null
    },
    isDateRangePredefined() {
      return this.currentView?.period?.daterangePredefined != null
    },
    toDatePickerFormattedValue() {
      if (this.isDateRangePredefined) {
        return this.predefinedDateRanges.find(
          p => p.key == this.currentView?.period?.daterangePredefined
        )?.label ?? "Unknown range"
      }
      return null
    },
    dateFrom: {
      get() {
        return this.reportViewData ? this.reportViewData.period?.dateFrom : null
      },
      set(newDate) {
        this.setDate(
          normalizeDate(
            newDate, "start", this.reportViewData.granularity, this.dataweekStart
          ),
          "dateFrom"
        )
      }
    },
    dateTo: {
      get() {
        return this.reportViewData ? this.reportViewData?.period?.dateTo : null
      },
      set(newDate) {
        this.setDate(
          normalizeDate(
            newDate, "end", this.reportViewData.granularity, this.dataweekStart
          ),
          "dateTo"
        )
      }
    },
    isSaveViewButtonDisabled() {
      return this.viewToBeRenamed.title.length < 3
    },
    nPlots() {
      let n = 0
      for (const ds of this.reportViewData.datasets) {
        n += ds?.chartData?.y?.length ?? 0
      }
      return n
    },
    errorMessage() {
      if (this.nPlots > this.maxPlotsToShow) {
        return `There are ${this.nPlots} plots in the diagram - too many to show! ` +
          `The maximum number of plots that can be displayed is ${this.maxPlotsToShow}. ` +
          "Please apply filters to refine your selection."
      }
      return null
    },
    chartData() {
      if (this.nPlots > this.maxPlotsToShow) return { labels: [], datasets: [] }
      let reportViewData = this.reportViewData ? JSON.parse(JSON.stringify(this.reportViewData)) : {}
      let labels = this.reportViewData.chartData?.x || []
      const ax_cfg = this.getYAxesConfiguration(this.reportViewData)

      let sums = {}
      // calculate sums for relative datasets
      reportViewData?.datasets?.filter( (ds) => {
        return reportViewData?.style?.sourceType?.[ds?.sourceType]?.relative && ds.chartData.y.length
      }).forEach( (ds) => {
        const values = ds.chartData.y.reduce((a, b) => this.sumArrays(a, b))
        if (sums[ds.sourceType] == null) {
          sums[ds.sourceType] = values
        } else {
          sums[ds.sourceType] = this.sumArrays(sums[ds.sourceType], values)
        }
      })

      let datasets = reportViewData?.datasets?.reduce( (datasetDataSets, dataset, ds_index) => {
        // Line/Bar & colors
        let chartType = dataset.style?.chartType == "bar" ? "bar" : "line"
        let lineType = dataset.style?.lineType || "line"

        let colorMap
        let baseColor = dataset.style.color || "hotpink"
        const palettePrefix = 'palette:'
        const isPalette = baseColor.startsWith(palettePrefix)
        if (isPalette) {
          colorMap = palettes[baseColor.replace(palettePrefix, '')]
          if (!colorMap || colorMap.length == 0) {
            colorMap = ["hotpink"]
          }
        } else {
          colorMap = Util.createColorMap((dataset?.chartData?.y?.length || 0) + 1, baseColor, 0.65)
        }

        var color = null
        let datasetDataSet = dataset?.chartData?.y?.map( (y, index) => {
          color = Util.getNextItem(colorMap, color)
          const lineBorderDash = {
            "dotted-line": [2, 2],
            "dashed-line": [8, 6],
            "long-dashed-line": [16, 12],
            "dashed-dotted-line": [12, 6, 3, 6],
          }
          const lw = 1.5
          const isStacked = reportViewData?.style?.sourceType?.[dataset?.sourceType]?.stacked
          const relativeSum = sums[dataset.sourceType]
          const sourceTypeLabel = this.getSourceTypeConfig(dataset?.sourceType)?.label || dataset?.sourceType

          let extendedLabel = {
            ...dataset?.chartData?.legend?.[index],  // object with .group? & .label
            sourceTypeLabel: sourceTypeLabel,
            n_dataset: ds_index,
          }
          extendedLabel.fullLabel = (extendedLabel.group === null
            ? extendedLabel.label
            : extendedLabel.group + " - " + extendedLabel.label
          )

          return {
            hidden: dataset?.style?.invisible || dataset?.style?.y?.[index]?.invisible,
            // abusing "label: str" as object here to put more information into datapoints
            label: extendedLabel,
            lineTension: 0.15,
            type: chartType,
            pointRadius: chartType == 'line' ? (lineType == 'dotted' ? 3 : 0) : 0,
            pointHitRadius: 20,
            borderWidth: chartType == 'line' ? (lineType == 'dotted' ? 0 : lw) : lw,
            borderDash: chartType == 'line' ? lineBorderDash[lineType] ?? [] : [],
            borderColor: color,
            pointBackgroundColor: color,
            // uncomment to add some alpha to bar charts (if color palettes are used)
            // backgroundColor: chartType == "bar" && isPalette ? color + "40" : color,
            backgroundColor: color,
            data: relativeSum ? this.divideArrays(y, relativeSum) : y,
            stack: isStacked ? dataset?.sourceType : `s-${datasetDataSets.length}-${index}`,
            yAxisID: ax_cfg[dataset?.sourceType]?.axisID,
            options: {
              plugins: {
                legend: {
                  display: false,
                }
              }
            },
          }
        }) || []

        datasetDataSets = datasetDataSets.concat(datasetDataSet)

        return datasetDataSets
      }, []) || []

      let chartData = {
        labels: labels,
        datasets: datasets
      }
      return chartData;
    },
    title() {
      return this.reportViewData?.title || ""
    },
    yAxes() {
      const ax_cfg = this.getYAxesConfiguration(this.reportViewData)
      return Object.values(ax_cfg)
    },
    legendPlugin() {
      // Create a legend that depends on the datasets selected. If grouping is selected,
      // groups the entries under a common heading to avoid long labels with a lot of
      // repetitions. If no groups are selected, place the full labels centered under
      // plot.
      const randomId = this.randomId
      return {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
          // TODO: this whole function should probably be implemented as a vue component.
          console.log(args, options)

          // Reuse the built-in legendItems generator
          const items = chart.options.plugins.legend.labels.generateLabels(chart)
          let uls
          // When there is grouped data and at least 6 plots exist in total, show each group
          // individually, else show all labels in a regular grid
          const showGroupedLabels = items.some((item) => item.text.group !== null) && items.length >= 6
          if (!showGroupedLabels) {
            // create one legend ul for all datasets
            uls = recreateLegendLists(randomId, 1)
            const maxLabelLength = Math.max(
              ...items.map((item) => item.text.fullLabel.length)
            ) ?? 0
            uls[0].style.gridTemplateColumns = `repeat(auto-fit, ${maxLabelLength + 8}ch)`
          } else {
            // create one legend ul for each dataset
            const n_ds = items[items.length - 1].text.n_dataset + 1
            uls = recreateLegendLists(randomId, n_ds)
            for (let i = 0; i < n_ds; i++) {
              // calculate approx column width per grouping
              uls[i].classList.add("groupby")
              const maxLabelLengthInGroup = Math.max(
                ...items.map((item) => (item.text.n_dataset == i) * item.text.label.length)
              ) ?? 0
              if (maxLabelLengthInGroup == 0) {
                uls[i].style.gridTemplateColumns = "1fr"
              } else {
                uls[i].style.gridTemplateColumns = `repeat(auto-fit, ${maxLabelLengthInGroup + 8}ch)`
              }
            }
          }

          // now create the actual labels (as li)
          let prevDataset = null
          items.forEach(item => {
            const li = document.createElement('li')
            let text
            let ul
            if (showGroupedLabels) {
              ul = uls[item.text.n_dataset]

              text = document.createTextNode(item.text.label)
              if (prevDataset != item.text.n_dataset) {
                // a new groupby entry, add a group
                let grp_li = document.createElement('li')
                grp_li.classList.add("header")
                let label = document.createTextNode(
                  item.text.group ?? item.text.sourceTypeLabel ?? item.text.label
                )
                grp_li.appendChild(label)
                ul.appendChild(grp_li)

                li.classList.add("new-row")
                prevDataset = item.text.n_dataset
              }
            } else {
              ul = uls[0]
              text = document.createTextNode(item.text.fullLabel)
            }

            li.onclick = () => {
              const isVisible = chart.isDatasetVisible(item.datasetIndex)
              chart.setDatasetVisibility(item.datasetIndex, !isVisible)
              chart.update()
            }
            li.ondblclick = () => {
              const isVisible = chart.isDatasetVisible(item.datasetIndex)
              const allOthersHidden = items.every(
                (i) => item.datasetIndex == i.datasetIndex || !chart.isDatasetVisible(i.datasetIndex)
              )
              if (isVisible && allOthersHidden) {
                // current visible, all others hidden => active all
                for (const i of items) {
                  chart.setDatasetVisibility(i.datasetIndex, true)
                }
              } else {
                // make me visible, hide all others
                for (const i of items) {
                  chart.setDatasetVisibility(i.datasetIndex, item.datasetIndex == i.datasetIndex)
                }
              }
              chart.update();
            };

            // Color box
            const createColorBox = () => {
              let boxSpan = document.createElement('span');
              boxSpan.style.background = item.fillStyle;
              boxSpan.style.borderColor = item.strokeStyle;
              boxSpan.style.borderWidth = item.lineWidth + 'px';
              boxSpan.style.display = 'inline-block';
              boxSpan.style.flexShrink = 0;
              return boxSpan
            }

            if (item.lineJoin == null) {
              // bar chart
              let boxSpan = createColorBox()
              boxSpan.style.height = '14px'
              boxSpan.style.width = '14px'
              boxSpan.style.margin = '5px 5px'
              li.appendChild(boxSpan)
            } else {
              if (item.lineDash?.length < 2) {
                // normal line chart
                let boxSpan = createColorBox()
                boxSpan.style.height = '4px'
                boxSpan.style.width = '14px'
                boxSpan.style.margin = '10px 5px'
                li.appendChild(boxSpan)
              } else {
                // dashed line chart, create 2 boxes
                const makeLineBox = () => {
                  let box = createColorBox()
                  box.style.height = '4px'
                  box.style.marginTop = "10px"
                  box.style.marginBottom = "10px"
                  box.style.width = '6px'
                  return box
                }
                let box1 = makeLineBox()
                let box2 = makeLineBox()
                box1.style.marginLeft = "5px"
                box1.style.marginRight = "1px"
                box2.style.marginLeft = "1px"
                box2.style.marginRight = "5px"
                li.appendChild(box1)
                li.appendChild(box2)
              }
            }

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = "2px 0px";
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            textContainer.appendChild(text);

            li.appendChild(textContainer);
            ul.appendChild(li);
          });
        }
      }
    },
    chartPlugins() {
      return [this.legendPlugin]
    },
    chartSubtitle() {
      return this.numberOfDatasetsTitle(this.reportViewData)
    },
    granularityOptions() {
      const granularities = this.$store.getters["config/granularities"] || []
      return granularities.map(g => {return {label: g.label, value: g.key}})
    },
    contextMenuItems() {
      return [
        {
          title: "Rename",
          handler: (reportViewData) => {
              useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
              this.onRenameView(reportViewData)
            },
        },
        {
          title: "Delete",
          handler: () => {
              useUI(this.$store).blockUI({ complete: true })
              this.$emit('view:delete', this.viewIndex)
            },
        },
      ];
    },
  },
  watch: {
  }
};

const recreateLegendLists = (id, n) => {
  // Recreates the elements inside a container by first removing all children and then
  // creating ul.legend-lists. Works directly in the dom of the current document.
  //
  // Args:
  //   id: id of the containing (parent) element
  //   n: number of ul.legend-list items to create
  // Returns:
  //   array of the newly created elements
  const legendContainer = document.getElementById(id)
  while (legendContainer.firstChild) {
    legendContainer.firstChild.remove()
  }

  let result = []
  for (let i = 0; i < n; ++i) {
    const listContainer = document.createElement('ul')
    listContainer.classList.add("legend-list")
    legendContainer.appendChild(listContainer)
    result.push(listContainer)
  }
  return result
}

</script>

<style scoped>
.report-view {
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: var(--std-box-shadow);
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 7px;
  border: 1px solid transparent;
}
.report-view.show-active {
  cursor: pointer;
}
.report-view.show-active.active {
  border: 1px solid var(--c-button-blue);
}
.report-view-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: var(--c-button-blue);
  width: 100%;
  padding-top: 10px;
}

.report-chart {
  flex-grow: 0;
  padding: 20px 40px 0px 40px;
}
.date-picker-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.granularity-date-picker {
  margin-right: 10px;
}

:deep(.chart) {
  margin: 0;
}
.icon-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 24px;
  height: 24px;
}
.icon-button:hover {
  color: var(--c-link-blue);
}

.context-menu-button {
  position: absolute;
  top: 5px;
  right: 0px;
}
:deep(.chart-wrapper) {
  min-height: 400px;
  max-height: 400px;
}
:deep(.no-data) {
  display: none;
}

:deep(.chart-diagram .header) {
  position: relative;
  padding-right: 30px;
}
.from-date-picker {
  margin-right: 10px;
  transition: width 0.5s ease-in-out;
}
.to-date-picker {
  margin-right: 10px;
  /* transition: width 0.5s ease-in-out; */
}
.from-date-picker.expanded {
  width: 430px;
  /* transition: width 0.5s ease-in-out; */
}
:deep(.from-date-picker.expanded .mx-datepicker) {
  width: 430px;
  /* transition: width 0.5s ease-in-out; */
}

.granularity {
  min-width: 260px;
}

</style>

<style>

div.mx-datepicker-main.mx-datepicker-popup.undefined > div.mx-datepicker-sidebar > button:nth-child(2) {
  color: var(--c-medium-grey);
  font-style: italic;
}

.legend-container {
  font-size: 11px;
  width: 100%;
  font-family: sans-serif;
  padding: 5px 40px 10px 40px;
  grid-template-columns: 100px 1fr;
  flex-grow: 3;
}

.legend-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 35ch); /* size changed via js */
  margin: 0;
  padding: 0;
}

.legend-list.groupby {
  justify-content: left;
  margin-left: 40px;
}

.legend-list li {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.legend-list .new-row {
  grid-column-start: 1;
}

.legend-list .header {
  white-space: nowrap;
  grid-column-start: 1;
  padding-top: 5px;
}

</style>
